<template>
  <svg
    fill="none"
    height="124"
    viewBox="0 0 204 124"
    width="204"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="#D9D9D9"
      height="15"
      rx="7.5"
      width="204"
      y="108.865"
    />
    <path
      clip-rule="evenodd"
      d="M114.235 13.0006C114.336 12.398 114.389 11.7791 114.389 11.1479C114.389 4.99107 109.397 0 103.241 0C97.0839 0 92.0928 4.99107 92.0928 11.1479C92.0928 11.7791 92.1453 12.398 92.246 13.0006C46.1213 18.1865 10.3416 55.595 10.3416 100.972C10.4291 101.278 10.5286 101.576 10.6399 101.865H196.077C196.118 101.576 196.139 101.278 196.139 100.972C196.139 55.5952 160.36 18.1867 114.235 13.0006ZM66.3241 111.069C44.6077 111.653 28.3614 112.09 19.002 108.865H186.884C175.57 111.837 154.883 111.42 130.442 110.928H130.441C120.445 110.726 109.821 110.512 98.9528 110.512C87.0485 110.512 76.1288 110.806 66.3241 111.069ZM94.569 24.7733C83.0764 30.9543 57.7466 53.4806 48.3686 94.1378H27.6819C31.3595 74.6791 49.8856 33.5641 94.569 24.7733Z"
      fill="#D9D9D9"
      fill-rule="evenodd"
    />
  </svg>
</template>
